<template>
  <v-card class="pa-8">
    <header-content :pageTitle="'Edit Data Payment'" />

    <div>
      <!-- <h4 class="pb-4">Excel Input</h4>
      <VueFileAgent
        :theme="'list'"
        :deletable="true"
        :accept="
          '.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
        "
        :helpText="'Pilih file excel yang ingin diupload'"
        v-model="uploadedFile"
      ></VueFileAgent>
      <button-form
        :submit="submitPayment"
        :buttonText="'Upload'"
        class="pt-8"
      />

      <v-divider class="my-6" /> -->

      <h4 class="pb-4">Form Input</h4>
      <form-payment :formPayment="formNewPayment" />
      <button-form :submit="submitPayment" :loading="loading" class="pt-8" />
    </div>

    <popup-success
      :dialog="showSuccessDialog"
      :closeDialog="closeSuccessDialog"
    />
    <popup-error :dialog="showErrDialog" :closeDialog="closeErrDialog" />
  </v-card>
</template>

<script>
import store from "../../store/index";
import HeaderContent from "../../components/Header/HeaderContent.vue";
import StepperTransaction from "../../components/Stepper/StepperTransaction.vue";
import ButtonForm from "../../components/Button/ButtonForm.vue";
import FormTransaction from "../../components/Form/FormTransaction.vue";
import FormPayment from "../../components/Form/FormPaymentEdit.vue";
import PopupSuccess from "../../components/Popup/PopupSuccess.vue";
import PopupError from "../../components/Popup/PopupError.vue";

export default {
  components: {
    HeaderContent,
    StepperTransaction,
    ButtonForm,
    FormTransaction,
    FormPayment,
    PopupSuccess,
    PopupError,
  },

  data() {
    return {
      steps: 1,
      uploadedFile: "",
      showSuccessDialog: false,
      showErrDialog: false,
      loading: false,
      formNewPayment: {
        penyelenggara: "",
        idPinjaman: "",
        borrower: null,
        lender: null,
        idTransaksi: "",
        idPembayaran: "",
        tanggalJatuhTempoSelanjutnya: "",
        tanggalJatuhTempo: "",
        tanggalPenyaluranDana: "",
        tanggalPembayaranBorrower: "",
        tanggalPembayaranPenyelenggara: "",
        tanggalPelunasanBorrower: "",
        tanggalPelunasanPenyelenggara: "",
        report: null,
        sisaPinjamanBerjalan: "",
        statusPinjaman: null,
        denda: "",
        nilaiPembayaran: "",
      },
    };
  },

  async mounted() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
    await store.dispatch("office/fetchPayment", this.$route.params.id);
    this.formNewPayment = {
      penyelenggara: store.state.office.currentPayment.id_penyelenggara,
      idPinjaman: store.state.office.currentPayment.id_pinjaman,
      borrower: store.state.office.currentPayment.id_borrower,
      lender: store.state.office.currentPayment.id_lender,
      idTransaksi: store.state.office.currentPayment.id_transaksi,
      idPembayaran: store.state.office.currentPayment.id_pembayaran,
      tanggalJatuhTempoSelanjutnya: store.state.office.currentPayment.tgl_jatuh_tempo_selanjutnya.substring(
        0,
        10
      ),
      tanggalJatuhTempo: store.state.office.currentPayment.tgl_jatuh_tempo.substring(
        0,
        10
      ),
      tanggalPenyaluranDana: store.state.office.currentPayment.tgl_penyaluran_dana.substring(
        0,
        10
      ),
      tanggalPembayaranBorrower: store.state.office.currentPayment
        .tgl_pembayaran_borrower
        ? store.state.office.currentPayment.tgl_pembayaran_borrower.substring(
            0,
            10
          )
        : null,
      tanggalPembayaranPenyelenggara: store.state.office.currentPayment.tgl_pembayaran_penyelenggara.substring(
        0,
        10
      ),
      tanggalPelunasanBorrower: store.state.office.currentPayment
        .tgl_pelunasan_borrower
        ? store.state.office.currentPayment.tgl_pelunasan_borrower.substring(
            0,
            10
          )
        : null,
      tanggalPelunasanPenyelenggara: store.state.office.currentPayment.tgl_pelunasan_penyelenggara.substring(
        0,
        10
      ),
      sisaPinjamanBerjalan:
        store.state.office.currentPayment.sisa_pinjaman_berjalan,
      statusPinjaman: store.state.office.currentPayment.id_status_pinjaman,
      denda: store.state.office.currentPayment.denda,
      nilaiPembayaran: store.state.office.currentPayment.nilai_pembayaran,
    };
  },

  updated() {
    if (this.$route.params.status) {
      this.steps = 2;
    } else {
      this.steps = 1;
    }
  },

  methods: {
    closeSuccessDialog() {
      this.showSuccessDialog = !this.showSuccessDialog;
      this.$router.push("/list-payment");
    },
    closeErrDialog() {
      this.showErrDialog = !this.showErrDialog;
    },
    async submitPayment() {
      this.uploadedFile = "";
      this.loading = true;

      try {
        console.log(this.formNewPayment);
        await store
          .dispatch("office/editPayment", {
            id: this.$route.params.id,
            data: this.formNewPayment,
          })
          .then((r) => {
            if (r) {
              console.log(r, "sukses");
              this.showSuccessDialog = true;
              this.loading = false;
            } else {
              this.showErrDialog = true;
              this.loading = false;
            }
          });
        this.showSuccessDialog = true;
      } catch (err) {
        this.showErrDialog = true;
        this.loading = false;
      }
    },
  },
};
</script>
