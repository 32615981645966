<template>
  <v-form
    class="multi-col-validation"
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-row>
      <v-col cols="12">
        <v-text-field
          v-model="formPayment.penyelenggara"
          :rules="[(v) => !!v || 'Mohon isi penyelenggara']"
          label="Penyelenggara"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="formPayment.idPinjaman"
          :items="statesLoan"
          item-text="id_pinjaman"
          item-value="id"
          label="ID Pinjaman"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="formPayment.borrower"
          :items="statesBorrower"
          item-text="id_borrower"
          item-value="id"
          label="Borrower"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-autocomplete
          v-model="formPayment.lender"
          :items="states"
          item-text="id_lender"
          item-value="id"
          label="Lender"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <!-- <v-text-field
          v-model="formData.idTransaksi"
          :rules="[(v) => !!v || 'Mohon isi ID transaksi']"
          label="ID Transaksi"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field> -->
        <v-autocomplete
          v-model="formPayment.idTransaksi"
          :items="statesTransaction"
          label="ID Transaksi"
          item-text="id_transaksi"
          item-value="id"
          cache-items="true"
          color="primary"
          :loading="loading"
          outlined
          dense
          required
          hide-details
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formPayment.idPembayaran"
          :rules="[(v) => !!v || 'Mohon isi ID pembayaran']"
          label="ID Pembayaran"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalJatuhTempoSelanjutnyaMenu"
          v-model="tanggalJatuhTempoSelanjutnyaMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalJatuhTempoSelanjutnya"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalJatuhTempoSelanjutnya"
              label="Tanggal Jatuh Tempo Selanjutnya"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalJatuhTempoSelanjutnya"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalJatuhTempoSelanjutnyaMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalJatuhTempoSelanjutnyaMenu.save(
                  formPayment.tanggalJatuhTempoSelanjutnya
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalJatuhTempoMenu"
          v-model="tanggalJatuhTempoMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalJatuhTempo"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalJatuhTempo"
              label="Tanggal Jatuh Tempo"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalJatuhTempo"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="tanggalJatuhTempoMenu = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalJatuhTempoMenu.save(formPayment.tanggalJatuhTempo)
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPenyaluranDanaMenu"
          v-model="tanggalPenyaluranDanaMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalPenyaluranDana"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalPenyaluranDana"
              label="Tanggal Penyaluran Dana"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalPenyaluranDana"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPenyaluranDanaMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPenyaluranDanaMenu.save(
                  formPayment.tanggalPenyaluranDana
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPembayaranBorrowerMenu"
          v-model="tanggalPembayaranBorrowerMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalPembayaranBorrower"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalPembayaranBorrower"
              label="Tanggal Pembayaran Borrower"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalPembayaranBorrower"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPembayaranBorrowerMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPembayaranBorrowerMenu.save(
                  formPayment.tanggalPembayaranBorrower
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPembayaranPenyelenggaraMenu"
          v-model="tanggalPembayaranPenyelenggaraMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalPembayaranPenyelenggara"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalPembayaranPenyelenggara"
              label="Tanggal Pembayaran Penyelenggara"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalPembayaranPenyelenggara"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPembayaranPenyelenggaraMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPembayaranPenyelenggaraMenu.save(
                  formPayment.tanggalPembayaranPenyelenggara
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12">
        <v-menu
          ref="tanggalPelunasanBorrowerMenu"
          v-model="tanggalPelunasanBorrowerMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalPelunasanBorrower"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalPelunasanBorrower"
              label="Tanggal Pelunasan Borrower"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalPelunasanBorrower"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPelunasanBorrowerMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPelunasanBorrowerMenu.save(
                  formPayment.tanggalPelunasanBorrower
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <v-menu
          ref="tanggalPelunasanPenyelenggaraMenu"
          v-model="tanggalPelunasanPenyelenggaraMenu"
          :close-on-content-click="false"
          :return-value.sync="formPayment.tanggalPelunasanPenyelenggara"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="formPayment.tanggalPelunasanPenyelenggara"
              label="Tanggal Pelunasan Penyelenggara"
              readonly
              v-bind="attrs"
              v-on="on"
              color="primary"
              outlined
              dense
              hide-details
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="formPayment.tanggalPelunasanPenyelenggara"
            color="primary"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="tanggalPelunasanPenyelenggaraMenu = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="
                $refs.tanggalPelunasanPenyelenggaraMenu.save(
                  formPayment.tanggalPelunasanPenyelenggara
                )
              "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>

      <!-- <v-col cols="12" md="6">
        <v-select
          v-model="formData.report"
          :items="reportOptions"
          item-text="name"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih report']"
          label="Report"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col> -->

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formPayment.sisaPinjamanBerjalan"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi sisa pinjaman berjalan']"
          label="Sisa Pinjaman Berjalan"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="formPayment.statusPinjaman"
          :items="statusPinjamanOptions"
          item-text="name"
          item-value="value"
          :rules="[(v) => !!v || 'Mohon pilih status pinjaman']"
          label="Status Pinjaman"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-select>
      </v-col>

      <v-col cols="12" md="6">
        <v-text-field
          v-model="formPayment.denda"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi denda']"
          label="Denda"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>

      <v-col cols="12">
        <v-text-field
          v-model="formPayment.nilaiPembayaran"
          type="number"
          :rules="[(v) => !!v || 'Mohon isi nilai pembayaran']"
          label="Nilai Pembayaran"
          color="primary"
          outlined
          dense
          required
          hide-details
        ></v-text-field>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import store from "../../store/index.js";

export default {
  props: {
    formPayment: Object,
  },
  data: () => ({
    valid: true,
    loading: false,
    tanggalJatuhTempoSelanjutnyaMenu: false,
    tanggalJatuhTempoMenu: false,
    tanggalPenyaluranDanaMenu: false,
    tanggalPembayaranBorrowerMenu: false,
    tanggalPembayaranPenyelenggaraMenu: false,
    tanggalPelunasanBorrowerMenu: false,
    tanggalPelunasanPenyelenggaraMenu: false,
    statusPinjamanOptions: [
      {
        name: "Lancar (<30 Hari)",
        value: 1,
      },
      {
        name: "Tidak Lancar (30 s/d 90 Hari)",
        value: 2,
      },
      {
        name: "Macet (>90)",
        value: 3,
      },
    ],
    states: [],
    statesBorrower: [],
    statesLoan: [],
    statesTransaction: [],
  }),

  async mounted() {
    // this.formData = this.formPayment;
    this.loading = "primary";

    await store.dispatch("office/fetchAllBorrower").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllLoan").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllLender").then((r) => {
      if (r) this.loading = false;
    });

    await store.dispatch("office/fetchAllTransaction").then((r) => {
      if (r) this.loading = false;
    });

    this.states = store.state.office.allLender;
    this.statesBorrower = store.state.office.allBorrower;
    this.statesLoan = store.state.office.allLoan;
    this.statesTransaction = store.state.office.allTransaction;
  },
};
</script>
